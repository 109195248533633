<!-- TODO : Temporary, waiting for daisyUI switch for star component -->
<script setup lang="ts">
import StarRating from 'vue-star-rating'

const props = defineProps({
  collectId: {
    type: String,
    required: true,
  },
})

const { rateCollectNps } = useCollectStore()
const open = defineModel<boolean>()
const rating = defineModel<number>('rating', { default: 0 })
const ratingDetails = defineModel<string>('description', { default: '' })

const { t } = useI18n()

async function rateCollect() {
  await rateCollectNps({ rating: rating.value, additionalDetails: ratingDetails.value, collectId: props.collectId })
  open.value = false
}
</script>

<template>
  <WModal v-model="open">
    <template #title>
      <div class="flex flex-col gap-4">
        <p class="text-xl font-semibold">
          {{ t("global.rate_collect") }}
        </p>
      </div>
    </template>
    <template #default>
      <div class="flex grow justify-center py-4 flex-col">
        <div class="flex justify-center">
          <StarRating v-model:rating="rating" :show-rating="false" />
        </div>

        <div class="form-control my-2 w-full">
          <label for="rating_details" class="label">
            <span class="label-text">{{ $t('global.rating_details') }}</span>
          </label>
          <textarea
            id="rating_details"
            v-model.trim="ratingDetails"
            rows="4"
            name="rating_details"
            placeholder="5 stars - Great service"
            class="textarea input-bordered input-sm text-base-content w-full"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="w-full flex place-items-center items-center justify-end gap-2">
        <button class="btn-secondary" @click="open = false">
          {{ t("global.cancel") }}
        </button>
        <button class="btn-primary" @click="rateCollect">
          {{ t("global.rate_collect") }}
        </button>
      </div>
    </template>
  </WModal>
</template>
