import { gql } from '@apollo/client/core'

export const SERVICES_QUERY_LIST = gql`
  query Services {
    waste_service {
      _created_at
      data
      end_datetime
      id
      start_datetime
      status
      type_id
      wp_site_id
      wr_site_id
      wr_client_id
      wp_client_id
      collection_price
      transport_price
      license_plate
      co2_amount
      producer {
        name
        id
      }
      recycler {
        name
        id
      }
      wp_site {
        id
        client_id
        client {
          name
        }
      }
      wr_site {
        id
        client_id
        client {
          name
        }
      }
      incidents {
        photo_url
        penalty_comment
        penalty_amount
        incident_type
        incident_status
        _created_at
        _created_by
        _updated_at
        _updated_by
        id
        comments
        waste_service_id
      }
      waste_service_raw_materials_containers {
        id
        fill_rate
        quantity
        unit
        treatment_code
        raw_materials_container {
          name
          client_id
          id
          photo_url
          quantity
          site_id
          raw_material {
            id
            is_sold
            name
            price
            prices_lists {
              id
              end_date
              price
              rm_id
              start_date
              status
              type
              unit
              wp_id
              wr_id
              vat
            }
          }
        }
      }
    }
  }
`

export const SERVICES_QUERY_UPDATE = gql`
  mutation UpdateService($id: Int!, $object: waste_service_set_input!) {
    update_waste_service_by_pk(pk_columns: { id: $id }, _set: $object) {
      data
      end_datetime
      id
      start_datetime
      _created_at
      status
      type_id
      wp_site_id
      wr_site_id
      wr_client_id
      wp_client_id
      collection_price
      transport_price
      co2_amount
      license_plate
      producer {
        name
        id
      }
      recycler {
        name
        id
      }
      wp_site {
        id
        client_id
        client {
          name
        }
      }
      wr_site {
        id
        client_id
        client {
          name
        }
      }
      waste_service_raw_materials_containers {
        id
        fill_rate
        quantity
        unit
        treatment_code
        raw_materials_container {
          name
          client_id
          id
          photo_url
          quantity
          site_id
          raw_material {
            id
            is_sold
            name
            price
            prices_lists {
              id
              end_date
              price
              rm_id
              start_date
              status
              type
              unit
              wp_id
              wr_id
              vat
            }
          }
        }
      }
    }
  }
`

// export const UPDATE_WS_RM_CONTAINERS = gql`
//   query UpdateWSRMContainers($updates: [waste_service_raw_materials_containers_updates!]!) {
//     update_waste_service_raw_materials_containers_many(updates: $updates) {
//       affected_rows
//     }
//   }
// `;

export const SIGN_BSD = gql`
  mutation SignBSD($input: SignBSDInput!) {
    signBSD(input: $input) {
      collectId
      tdState
      state
    }
  }
`

export const WASTE_SERVICE_RAW_MATERIALS_CONTAINER_UPDATE = gql`
  mutation UpdateWasteServiceRawMaterialsContainer($updates: [waste_service_raw_materials_containers_updates!]!) {
    update_waste_service_raw_materials_containers_many(updates: $updates) {
      affected_rows
    }
  }
`

export const SERVICES_QUERY_GET = gql`
  query Service($id: Int!) {
    waste_service_by_pk(id: $id) {
      id
      data
      _created_at
      end_datetime
      start_datetime
      status
      type_id
      wp_site_id
      wr_site_id
      collection_price
      transport_price
      co2_amount
      license_plate
    }
  }
`
export const SERVICES_QUERY_INSERT = gql`
  mutation InsertService($object: waste_service_insert_input!) {
    insert_waste_service_one(object: $object) {
      data
      end_datetime
      _created_at
      id
      start_datetime
      status
      type_id
      wp_site_id
      wr_site_id
      wp_client_id
      wr_client_id
      collection_price
      license_plate
      transport_price
      producer {
        name
        id
      }
      recycler {
        name
        id
      }
      wp_site {
        client_id
        client {
          name
        }
      }
      wr_site {
        client_id
        client {
          name
        }
      }
      waste_service_raw_materials_containers {
        id
        fill_rate
        quantity
        unit
        treatment_code
        raw_materials_container {
          client_id
          id
          photo_url
          quantity
          site_id
          name
          raw_material {
            id
            name
            price
            is_sold
            prices_lists {
              id
              end_date
              price
              rm_id
              start_date
              status
              type
              unit
              wp_id
              wr_id
              vat
            }
          }
        }
      }
    }
  }
`

export const SERVICES_QUERY_DELETE = gql`
  mutation DeleteService($id: Int!) {
    delete_waste_service_by_pk(id: $id) {
      id
    }
  }
`

export const WASTE_SERVICE_RAW_MATERIALS_CONTAINER_DELETE = gql`
  mutation DeleteWasteServiceRawMaterialsContainer($id: Int!) {
    delete_waste_service_raw_materials_containers(where: { waste_service_id: { _eq: $id } }) {
      affected_rows
    }
  }
`

// PRODUCER
// Waste service raw materials containers

export const WASTE_SERVICE_RAW_MATERIALS_CONTAINER_DELETE_PRODUCER = gql`
  mutation DeleteWasteServiceRawMaterialsContainer($ids: [Int]!) {
    delete_waste_service_raw_materials_containers(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`

export const WASTE_SERVICE_RAW_MATERIALS_CONTAINER_UPDATE_PRODUCER = gql`
  mutation UpdateWasteServiceRawMaterialsContainer($updates: [waste_service_raw_materials_containers_updates!]!) {
    update_waste_service_raw_materials_containers_many(updates: $updates) {
      affected_rows
    }
  }
`

export const WASTE_SERVICE_RAW_MATERIALS_CONTAINER_INSERT_PRODUCER = gql`
  mutation InsertWasteServiceRawMaterialsContainer($objects: [waste_service_raw_materials_containers_insert_input!]!) {
    insert_waste_service_raw_materials_containers(objects: $objects) {
      affected_rows
    }
  }
`

export const WASTE_SERVICES_CONTAINERS_SUM_QUANTITY = gql`
  query WasteServicesContainersSumQuantity {
    waste_service_raw_materials_containers_aggregate {
      aggregate {
        sum {
          quantity
        }
      }
    }
  }
`

export const WASTE_SERVICES_CONTAINERS_SUM_CO2 = gql`
  query WasteServicesContainersSumCO2 {
    waste_service_aggregate {
      aggregate {
        sum {
          co2_amount
        }
      }
    }
  }
`
