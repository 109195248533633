export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
};

export type AddressGraphql = {
  __typename?: 'AddressGraphql';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
};

export enum AscDescGraphql {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type AttachmentGraphql = {
  __typename?: 'AttachmentGraphql';
  file_name: Scalars['String']['output'];
  msg: Scalars['String']['output'];
  signed_url: Scalars['String']['output'];
  size: Scalars['String']['output'];
};

export type BsdCollection = {
  __typename?: 'BSDCollection';
  collection: Array<Maybe<BsdGraphql>>;
  count: Scalars['Float']['output'];
};

export type BsdGraphql = {
  __typename?: 'BSDGraphql';
  id: Scalars['ID']['output'];
  state: CollectBsdState;
  tdState: TrackdechetsBsdState;
};

export type Co2Saved = {
  __typename?: 'CO2Saved';
  totalCO2Saved: Scalars['Float']['output'];
};

export type CancelCollectInputGraphql = {
  collectId: Scalars['ID']['input'];
};

export type ClientAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
};

export type ClientCollection = {
  __typename?: 'ClientCollection';
  collection: Array<Maybe<ClientGraphql>>;
  count: Scalars['Float']['output'];
};

export type ClientDocumentGraphql = StoredDocumentGraphql & {
  __typename?: 'ClientDocumentGraphql';
  client: ClientGraphql;
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  externalReference: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  type: DocumentType;
};

export type ClientFilterGraphql = {
  /** Uses a full text search on all clients */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ClientGraphql = {
  address?: Maybe<AddressGraphql>;
  autosignEnabledForTrackdechets: Scalars['Boolean']['output'];
  bic?: Maybe<Scalars['String']['output']>;
  contactFirstName?: Maybe<Scalars['String']['output']>;
  contactLastName?: Maybe<Scalars['String']['output']>;
  documents: StoredDocumentCollection;
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isConnectedToTrackdechets?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  siret?: Maybe<Scalars['String']['output']>;
  sites: SitesCollection;
  slug: Scalars['String']['output'];
};


export type ClientGraphqlDocumentsArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};


export type ClientGraphqlSitesArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};

export type ClientInput = {
  bic?: InputMaybe<Scalars['String']['input']>;
  clientAddress?: InputMaybe<ClientAddressInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  siret?: InputMaybe<Scalars['String']['input']>;
};

export enum ClientType {
  Producer = 'producer',
  Recycler = 'recycler'
}

export enum CollectBsdState {
  Signed = 'SIGNED',
  ToSignByWp = 'TO_SIGN_BY_WP',
  ToSignByWr = 'TO_SIGN_BY_WR'
}

export type CollectCsvExtractGraphql = {
  __typename?: 'CollectCSVExtractGraphql';
  B64Csv: Scalars['String']['output'];
};

export type CollectCsvExtractInput = {
  fromDate: Scalars['DateTime']['input'];
  toDate: Scalars['DateTime']['input'];
};

export type CollectDocumentGraphql = StoredDocumentGraphql & {
  __typename?: 'CollectDocumentGraphql';
  client: ClientGraphql;
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  externalReference: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  /** if provided by the user, the sub-category of document */
  subtype?: Maybe<DocumentMetaSubtypes>;
  type: DocumentType;
};

export type CollectGraphql = {
  __typename?: 'CollectGraphql';
  /** Related Trackdechets BSDs */
  BSDs: BsdCollection;
  /** The Waste Producer of the collect */
  client: ClientGraphql;
  co2Amount?: Maybe<Scalars['Float']['output']>;
  /** The containers that are handled in this collect */
  collectedContainers: CollectedContainerCollection;
  createdAt: Scalars['DateTime']['output'];
  /** The end destination of the collect. */
  destination?: Maybe<SiteGraphql>;
  documents: StoredDocumentCollection;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** Related incidents */
  incidents: IncidentCollection;
  licensePlate?: Maybe<Scalars['String']['output']>;
  /** The location for the pickup of the collect. */
  location: SiteGraphql;
  npsRating?: Maybe<NpsGraphql>;
  /** Once assigned, the set recycler of the collect. */
  recycler?: Maybe<RecyclerGraphql>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  status: CollectStatus;
  transportPrice?: Maybe<Scalars['Float']['output']>;
};


export type CollectGraphqlDocumentsArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};

export type CollectGraphqlFilters = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderBy?: InputMaybe<OrderByGraphql>;
  producerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  recyclerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<CollectStatus>>;
};

export enum CollectStatus {
  AdminInProgress = 'adminInProgress',
  Cancelled = 'cancelled',
  Confirmed = 'confirmed',
  Finished = 'finished',
  HasIncident = 'hasIncident',
  InTreatment = 'inTreatment',
  PickupConfirmed = 'pickupConfirmed',
  RequestSent = 'requestSent',
  WpCancel = 'wpCancel',
  WrCancel = 'wrCancel'
}

export type CollectedContainerCollection = {
  __typename?: 'CollectedContainerCollection';
  collection: Array<Maybe<CollectedContainerGraphql>>;
  count: Scalars['Float']['output'];
};

export type CollectedContainerDocumentGraphql = StoredDocumentGraphql & {
  __typename?: 'CollectedContainerDocumentGraphql';
  client: ClientGraphql;
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  externalReference: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  type: DocumentType;
};

export type CollectedContainerGraphql = {
  __typename?: 'CollectedContainerGraphql';
  container: ContainerGraphql;
  documents: StoredDocumentCollection;
  fillRate?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  material: MaterialGraphql;
  quantity?: Maybe<Scalars['Float']['output']>;
  treatmentCode?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
};


export type CollectedContainerGraphqlDocumentsArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};

export type CollectsCollection = {
  __typename?: 'CollectsCollection';
  collection: Array<Maybe<CollectGraphql>>;
  count: Scalars['Float']['output'];
};

export type ContainerDocumentGraphql = StoredDocumentGraphql & {
  __typename?: 'ContainerDocumentGraphql';
  client: ClientGraphql;
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  externalReference: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  type: DocumentType;
};

export type ContainerGraphql = {
  __typename?: 'ContainerGraphql';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type CreateBsdInput = {
  collectId: Scalars['ID']['input'];
};

export type CreateClientInput = {
  bic?: InputMaybe<Scalars['String']['input']>;
  clientAddress?: InputMaybe<ClientAddressInput>;
  firstName: Scalars['String']['input'];
  iban?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  siret: Scalars['String']['input'];
  type: ClientType;
};

export type CreateMaterialInputGraphql = {
  capCode?: InputMaybe<Scalars['String']['input']>;
  clientId: Scalars['ID']['input'];
  consistency: MaterialConsistence;
  isSold?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  recyclerId: Scalars['ID']['input'];
  wasteCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserInput = {
  clientId: Scalars['ID']['input'];
  disabled: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  function: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  role: UserRoles;
};

export type CreatedBsdGraphql = {
  __typename?: 'CreatedBSDGraphql';
  collectId: Scalars['ID']['output'];
};

export type CurrentUserGraphql = {
  __typename?: 'CurrentUserGraphql';
  client: ClientGraphql;
  user: UserGraphql;
};

export type DeclareIncidentGraphqlInput = {
  collectId: Scalars['ID']['input'];
  comment: Scalars['String']['input'];
  penaltyAmount: Scalars['Float']['input'];
  penaltyComment: Scalars['String']['input'];
  type: IncidentType;
};

export type DeleteMaterialInputGraphql = {
  materialId: Scalars['ID']['input'];
};

export type DeleteUserInput = {
  id: Scalars['ID']['input'];
};

export enum DocumentContext {
  Client = 'CLIENT',
  Collect = 'COLLECT',
  CollectedContainer = 'COLLECTED_CONTAINER',
  Container = 'CONTAINER',
  Incident = 'INCIDENT',
  Material = 'MATERIAL'
}

export type DocumentGraphql = {
  __typename?: 'DocumentGraphql';
  content: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum DocumentMetaSubtypes {
  Co2Report = 'co2_report',
  DestructionCertificate = 'destruction_certificate',
  Invoice = 'invoice',
  PurchaseOrder = 'purchase_order',
  TrackingSheet = 'tracking_sheet',
  Uncategorized = 'uncategorized',
  WeightingTicket = 'weighting_ticket'
}

export enum DocumentType {
  Document = 'DOCUMENT',
  Logo = 'LOGO',
  Picture = 'PICTURE'
}

export type GenerateSignedUploadUrlInput = {
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type GenerateWasteRegisterInput = {
  fromDate: Scalars['DateTime']['input'];
  toDate: Scalars['DateTime']['input'];
};

export type GeneratedSignedUploadUrl = {
  __typename?: 'GeneratedSignedUploadUrl';
  url: Scalars['String']['output'];
};

export type IncidentCollection = {
  __typename?: 'IncidentCollection';
  collection: Array<Maybe<IncidentGraphql>>;
  count: Scalars['Float']['output'];
};

export type IncidentDocumentGraphql = StoredDocumentGraphql & {
  __typename?: 'IncidentDocumentGraphql';
  client: ClientGraphql;
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  externalReference: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  type: DocumentType;
};

export type IncidentFiltersGraphql = {
  clientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type IncidentGraphql = {
  __typename?: 'IncidentGraphql';
  attachments?: Maybe<Array<AttachmentGraphql>>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdByUser: UserGraphql;
  documents: StoredDocumentCollection;
  id: Scalars['ID']['output'];
  penaltyAmount?: Maybe<Scalars['Float']['output']>;
  penaltyComment?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  status: IncidentStatus;
  type: IncidentType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type IncidentGraphqlDocumentsArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};

export enum IncidentStatus {
  Closed = 'CLOSED',
  Draft = 'DRAFT',
  New = 'NEW',
  Open = 'OPEN'
}

export enum IncidentType {
  AccessProblem = 'ACCESS_PROBLEM',
  MaterialNonConformity = 'MATERIAL_NON_CONFORMITY',
  MaterialUnavailability = 'MATERIAL_UNAVAILABILITY',
  Other = 'OTHER'
}

export type LogAsInput = {
  userId: Scalars['String']['input'];
};

export type LoggedAs = {
  __typename?: 'LoggedAs';
  userJWT: Scalars['String']['output'];
};

export type MaterialCollection = {
  __typename?: 'MaterialCollection';
  collection: Array<Maybe<MaterialGraphql>>;
  count: Scalars['Float']['output'];
};

/** The consistency of the material */
export enum MaterialConsistence {
  Doughy = 'DOUGHY',
  Gaseous = 'GASEOUS',
  Liquid = 'LIQUID',
  Solid = 'SOLID'
}

export type MaterialDocumentGraphql = StoredDocumentGraphql & {
  __typename?: 'MaterialDocumentGraphql';
  client: ClientGraphql;
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  externalReference: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  type: DocumentType;
};

export type MaterialFiltersGraphql = {
  clientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type MaterialGraphql = {
  __typename?: 'MaterialGraphql';
  activePricing?: Maybe<PricingGraphql>;
  capCode?: Maybe<Scalars['String']['output']>;
  consistency: MaterialConsistence;
  documents: StoredDocumentCollection;
  id: Scalars['ID']['output'];
  isSold?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  previousPricings?: Maybe<PricingCollection>;
  price?: Maybe<Scalars['Float']['output']>;
  recycler?: Maybe<RecyclerGraphql>;
  wasteCode?: Maybe<Scalars['String']['output']>;
};


export type MaterialGraphqlDocumentsArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};

export type MaterialQuantity = {
  __typename?: 'MaterialQuantity';
  materialName?: Maybe<Scalars['String']['output']>;
  materialQuantity?: Maybe<Scalars['Float']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelCollect: CollectGraphql;
  /** Provided that the user is authenticated to Trackdechets and that no BSD already exists for this collect, creates a BSD on Trackdechets */
  createBSDForCollect: CreatedBsdGraphql;
  /** ADMIN ONLY -- Creates a client */
  createClient: ClientGraphql;
  createMaterial: MaterialGraphql;
  /** ADMIN ONLY -- Creates a user */
  createUser: UserGraphql;
  declareIncidentOnCollect: IncidentGraphql;
  /** Admin-only -- Soft deletes a material */
  deleteMaterial: MaterialGraphql;
  deleteStoredDocument: Scalars['Boolean']['output'];
  /** ADMIN ONLY -- Deletes a user */
  deleteUser: Scalars['Boolean']['output'];
  generateSignedUploadUrl: GeneratedSignedUploadUrl;
  logAs?: Maybe<LoggedAs>;
  rateCollectNPS: CollectGraphql;
  signBSD: SignedBsdGraphql;
  storeDocumentReference: StoredDocumentGraphql;
  toggleTrackdechetsAutosign: ClientGraphql;
  updateClient: ClientGraphql;
  updateIncident: IncidentGraphql;
  updateMaterial: MaterialGraphql;
  /** ADMIN ONLY -- Updates a user */
  updateUser: UserGraphql;
};


export type MutationCancelCollectArgs = {
  input: CancelCollectInputGraphql;
};


export type MutationCreateBsdForCollectArgs = {
  input: CreateBsdInput;
};


export type MutationCreateClientArgs = {
  input: CreateClientInput;
};


export type MutationCreateMaterialArgs = {
  input: CreateMaterialInputGraphql;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeclareIncidentOnCollectArgs = {
  input: DeclareIncidentGraphqlInput;
};


export type MutationDeleteMaterialArgs = {
  input: DeleteMaterialInputGraphql;
};


export type MutationDeleteStoredDocumentArgs = {
  input: StoredDocumentFilters;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationGenerateSignedUploadUrlArgs = {
  input: GenerateSignedUploadUrlInput;
};


export type MutationLogAsArgs = {
  input: LogAsInput;
};


export type MutationRateCollectNpsArgs = {
  input: RateCollectNpsInputGraphql;
};


export type MutationSignBsdArgs = {
  input: SignBsdInput;
};


export type MutationStoreDocumentReferenceArgs = {
  input: StoreDocumentReferenceInput;
};


export type MutationUpdateClientArgs = {
  input: ClientInput;
};


export type MutationUpdateIncidentArgs = {
  input: UpdateIncidentGraphqlInput;
};


export type MutationUpdateMaterialArgs = {
  input: UpdateMaterialInputGraphql;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type NpsGraphql = {
  __typename?: 'NPSGraphql';
  additionalDetails?: Maybe<Scalars['String']['output']>;
  rating: Scalars['Float']['output'];
};

export type OrderByGraphql = {
  field: Scalars['String']['input'];
  order: AscDescGraphql;
};

export type PaginationArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
};

export type PricingCollection = {
  __typename?: 'PricingCollection';
  collection: Array<Maybe<PricingGraphql>>;
  count: Scalars['Float']['output'];
};

export type PricingGraphql = {
  __typename?: 'PricingGraphql';
  id: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
  unit: Scalars['String']['output'];
  vat: Scalars['Float']['output'];
};

export type ProducerCollection = {
  __typename?: 'ProducerCollection';
  collection: Array<Maybe<ProducerGraphql>>;
  count: Scalars['Float']['output'];
};

export type ProducerGraphql = ClientGraphql & {
  __typename?: 'ProducerGraphql';
  address?: Maybe<AddressGraphql>;
  autosignEnabledForTrackdechets: Scalars['Boolean']['output'];
  bic?: Maybe<Scalars['String']['output']>;
  contactFirstName?: Maybe<Scalars['String']['output']>;
  contactLastName?: Maybe<Scalars['String']['output']>;
  documents: StoredDocumentCollection;
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isConnectedToTrackdechets?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  recyclers: RecyclerCollection;
  siret?: Maybe<Scalars['String']['output']>;
  sites: SitesCollection;
  slug: Scalars['String']['output'];
};


export type ProducerGraphqlDocumentsArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};


export type ProducerGraphqlRecyclersArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};


export type ProducerGraphqlSitesArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};

export type Query = {
  __typename?: 'Query';
  clients: ClientCollection;
  co2Saved: Co2Saved;
  collectCSVExtract: CollectCsvExtractGraphql;
  /** The list of collects for the current user */
  collects: CollectsCollection;
  documents: StoredDocumentCollection;
  generateWasteRegister: DocumentGraphql;
  getWastePerMonthPerType?: Maybe<Array<WastePerMonthPerType>>;
  incidents: IncidentCollection;
  materials: MaterialCollection;
  me: CurrentUserGraphql;
  moneyPerMonthMetrics: Array<TotalMoneyPerMonth>;
  treatmentCodes: Array<TreatmentCodeGraphql>;
  /** ADMIN ONLY -- Fetches all users */
  users: UserCollection;
  wasteCodes: Array<WasteCodesGraphql>;
};


export type QueryClientsArgs = {
  filters: ClientFilterGraphql;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryCollectCsvExtractArgs = {
  input: CollectCsvExtractInput;
};


export type QueryCollectsArgs = {
  filters?: InputMaybe<CollectGraphqlFilters>;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryDocumentsArgs = {
  filters: StoredDocumentFilters;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryGenerateWasteRegisterArgs = {
  input: GenerateWasteRegisterInput;
};


export type QueryIncidentsArgs = {
  filters?: InputMaybe<IncidentFiltersGraphql>;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryMaterialsArgs = {
  filters: MaterialFiltersGraphql;
  pagination?: InputMaybe<PaginationArgs>;
};


export type QueryUsersArgs = {
  filters?: InputMaybe<UserFilterGraphql>;
  pagination?: InputMaybe<PaginationArgs>;
};

export type RateCollectNpsInputGraphql = {
  additionalDetails?: InputMaybe<Scalars['String']['input']>;
  collectId: Scalars['ID']['input'];
  rating: Scalars['Float']['input'];
};

export type RecyclerCollection = {
  __typename?: 'RecyclerCollection';
  collection: Array<Maybe<RecyclerGraphql>>;
  count: Scalars['Float']['output'];
};

export type RecyclerGraphql = ClientGraphql & {
  __typename?: 'RecyclerGraphql';
  address?: Maybe<AddressGraphql>;
  autosignEnabledForTrackdechets: Scalars['Boolean']['output'];
  bic?: Maybe<Scalars['String']['output']>;
  contactFirstName?: Maybe<Scalars['String']['output']>;
  contactLastName?: Maybe<Scalars['String']['output']>;
  documents: StoredDocumentCollection;
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isConnectedToTrackdechets?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  producers: ProducerCollection;
  siret?: Maybe<Scalars['String']['output']>;
  sites: SitesCollection;
  slug: Scalars['String']['output'];
};


export type RecyclerGraphqlDocumentsArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};


export type RecyclerGraphqlProducersArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};


export type RecyclerGraphqlSitesArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};

export type SignBsdInput = {
  collectId: Scalars['ID']['input'];
  signatureCode?: InputMaybe<Scalars['Float']['input']>;
};

export type SignedBsdGraphql = {
  __typename?: 'SignedBSDGraphql';
  collectId: Scalars['ID']['output'];
  isSigned: Scalars['Boolean']['output'];
  state: Scalars['String']['output'];
  tdState: TrackdechetsBsdState;
};

export type SiteGraphql = {
  __typename?: 'SiteGraphql';
  address?: Maybe<Scalars['String']['output']>;
  client: ClientGraphql;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type SitesCollection = {
  __typename?: 'SitesCollection';
  collection: Array<Maybe<SiteGraphql>>;
  count: Scalars['Float']['output'];
};

export type StoreDocumentReferenceInput = {
  /** Allows an admin to upload a document to a specific client */
  clientId?: InputMaybe<Scalars['ID']['input']>;
  /** The other object this document relates to. */
  externalReference: DocumentContext;
  /** the name of the file with its extension */
  fileName: Scalars['String']['input'];
  /** the unique ID of the object referenced in the externalReference */
  referenceObjectId?: InputMaybe<Scalars['ID']['input']>;
  /** Only for a collect document. The subtype of the document */
  subtype?: InputMaybe<DocumentMetaSubtypes>;
  type: DocumentType;
};

export type StoredDocumentCollection = {
  __typename?: 'StoredDocumentCollection';
  collection: Array<Maybe<StoredDocumentGraphql>>;
  count: Scalars['Float']['output'];
};

export type StoredDocumentFilters = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type StoredDocumentGraphql = {
  client: ClientGraphql;
  createdAt: Scalars['DateTime']['output'];
  extension: Scalars['String']['output'];
  externalReference: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  type: DocumentType;
};

export type TotalMoneyPerMonth = {
  __typename?: 'TotalMoneyPerMonth';
  end: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
  totalsMetric: TotalsMetric;
};

export type TotalsMetric = {
  __typename?: 'TotalsMetric';
  totalIncidents: Scalars['Float']['output'];
  /** @deprecated Use totalMaterialsEarnings and totalMaterialsCosts instead */
  totalMaterials?: Maybe<Scalars['Float']['output']>;
  totalMaterialsCosts: Scalars['Float']['output'];
  totalMaterialsEarnings: Scalars['Float']['output'];
  totalTransport: Scalars['Float']['output'];
};

export enum TrackdechetsBsdState {
  Accepted = 'ACCEPTED',
  Draft = 'DRAFT',
  Processed = 'PROCESSED',
  Sealed = 'SEALED',
  Sent = 'SENT',
  SignedByProducer = 'SIGNED_BY_PRODUCER'
}

export type TreatmentCodeGraphql = {
  __typename?: 'TreatmentCodeGraphql';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  treatmentKind?: Maybe<TreatmentName>;
};

export enum TreatmentName {
  Bury = 'BURY',
  Incinerate = 'INCINERATE',
  Recycle = 'RECYCLE',
  Reuse = 'REUSE'
}

export type UpdateIncidentGraphqlInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  incidentId: Scalars['ID']['input'];
  penaltyAmount?: InputMaybe<Scalars['Float']['input']>;
  penaltyComment?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<IncidentStatus>;
  type?: InputMaybe<IncidentType>;
};

export type UpdateMaterialInputGraphql = {
  capCode?: InputMaybe<Scalars['String']['input']>;
  consistency?: InputMaybe<MaterialConsistence>;
  isSold?: InputMaybe<Scalars['Boolean']['input']>;
  materialId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  recyclerId?: InputMaybe<Scalars['ID']['input']>;
  wasteCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  function?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserRoles>;
};

export type UserCollection = {
  __typename?: 'UserCollection';
  collection: Array<Maybe<UserGraphql>>;
  count: Scalars['Float']['output'];
};

export type UserFilterGraphql = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Searches all users using text. Overrides all other parameters. */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type UserGraphql = {
  __typename?: 'UserGraphql';
  client: ClientGraphql;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  /** Will sometimes be not provided depending on context as we consider this field to be confidential. */
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  function?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  role: UserRoles;
};

export enum UserRoles {
  AdminApp = 'admin_app',
  User = 'user'
}

export type WasteCodeGraphql = {
  __typename?: 'WasteCodeGraphql';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type WasteCodeSubCategoryGraphql = {
  __typename?: 'WasteCodeSubCategoryGraphql';
  codes: Array<WasteCodeGraphql>;
  subCategoryCode: Scalars['String']['output'];
  subCategoryDescription: Scalars['String']['output'];
};

export type WasteCodesGraphql = {
  __typename?: 'WasteCodesGraphql';
  categoryCode: Scalars['String']['output'];
  categoryDescription: Scalars['String']['output'];
  subCategories: Array<WasteCodeSubCategoryGraphql>;
};

export type WastePerMonthPerType = {
  __typename?: 'WastePerMonthPerType';
  end: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
  totalQuantityInMonth: Array<MaterialQuantity>;
  unit: Scalars['String']['output'];
};
