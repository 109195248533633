<script setup lang="ts">
import {
  CheckCircle,
  Clock,
  Plus,
  X,
} from 'lucide-vue-next'

const { t } = useI18n()
const { getCollects, collects, lastAddedService } = storeToRefs(useCollectsStore())

const isLoading = ref<boolean>(false)
const noServices = ref<boolean>(false)
const showConfirmationCreation = ref<boolean>(false)
const showConfirmationEditing = ref<boolean>(false)
const isProducer = computed(() => userStore().isProducer)
const header = inject('header') as any

const router = useRouter()

const tabs = reactive({
  list: [t('global.collections'), t('global.incidents'), t('global.other_services')],
  selected: 0,
})

async function loadData() {
  isLoading.value = true
  header.title = t('global.services')

  if (!userStore().stores_loaded)
    return
  if (getCollects.value.length === 0) {
    noServices.value = true
  }

  const step = serviceCreationStore().getStep

  if (step === 6) {
    if (serviceCreationStore().isFinishCreating) {
      showConfirmationCreation.value = true
      serviceCreationStore().setisFinishCreating(false)
      serviceCreationStore().cleanObject()
    }
    if (serviceCreationStore().isFinishEditing) {
      showConfirmationEditing.value = true
      serviceCreationStore().setIsEditing(false)
      serviceCreationStore().cleanObject()
    }
  }
  isLoading.value = false
}

function newService() {
  router.push(`/waste-collection/new`)
}

function editService(id: any) {
  serviceCreationStore().cleanObject()
  router.push(`/waste-collection/${id}`)
}

function closeConfirmation() {
  showConfirmationCreation.value = false
  serviceCreationStore().cleanObject()
}
function closeEditConfirmation() {
  showConfirmationEditing.value = false
  serviceCreationStore().cleanObject()
}

watch(
  () => serviceCreationStore().isFinishCreating,
  (value) => {
    if (value) {
      showConfirmationCreation.value = true
    }
  },
)

watch(
  () => serviceCreationStore().isFinishEditing,
  (value) => {
    if (value) {
      showConfirmationEditing.value = true
    }
  },
)

onMounted(() => {
  loadData()
})
</script>

<template>
  <!-- <HeaderMobile :title="t('global.services')" class="md:hidden" /> -->
  <div class="max-w-[800px] md:mx-auto w-full flex flex-col grow h-[calc(100%-84px)] overflow-auto">
    <div class="mx-3 flex items-center justify-center">
      <OTab v-model="tabs.selected" :list-tabs="tabs.list" />
    </div>
    <div v-if="!isLoading" class="h-auto flex flex-1 flex-col bg-[#FAFAFA] overflow-auto">
      <template v-if="tabs.selected === 0">
        <Recollection :collects="getCollects" :collects-count="collects.count" :is-services="noServices" />
      </template>
      <template v-if="tabs.selected === 1">
        <Incidents />
      </template>
      <template v-if="tabs.selected === 2">
        <div class="flex flex-row items-center justify-center gap-2 p-4 text-center text-sm text-gray-400 italic">
          <Clock :size="18" />
          <p class="">
            {{ t("global.coming_soon") }}
          </p>
        </div>
      </template>

      <div v-if="isProducer && tabs.selected === 0" class="pointer-events-none fixed inset-0 z-10">
        <div
          class="pointer-events-auto absolute bottom-18 right-5 h-12 w-12 flex flex-col cursor-pointer items-center justify-center gap-2 rounded-full bg-[#15C28E] p-2.5 text-3xl text-white shadow md:hidden"
          @click="newService()"
        >
          <Plus :size="18" />
        </div>
      </div>
    </div>
    <Loader v-else />
  </div>
  <transition
    enter-active-class="transition ease-out duration-300"
    enter-from-class="transform translate-y-full opacity-0"
    enter-to-class="transform translate-y-0 opacity-100"
    leave-active-class="transition ease-in duration-200"
    leave-from-class="transform translate-y-0 opacity-100"
    leave-to-class="transform translate-y-full opacity-0"
  >
    <div v-if="showConfirmationCreation && !showConfirmationEditing">
      <div class="fixed inset-0 z-5 bg-black opacity-50" />
      <div
        class="absolute fixed bottom-0 right-0.5 z-10 w-full rounded-2xl rounded-bl-none rounded-br-none bg-white p-5 md:p-8"
      >
        <div class="mb-4 flex items-center justify-between border-b border-[#D4D6DD] p-4">
          <div class="flex items-center gap-2">
            <CheckCircle :size="24" color="15C28E" />
            <p class="text-xl text-[#595959] font-semibold">
              {{ t("global.request_sent") }}
            </p>
          </div>
          <X :size="18" @click="closeConfirmation" />
        </div>
        <p class="mb-4 text-[#595959] md:text-xl">
          {{ t("global.request_sent_answer_soon") }}
        </p>
        <div class="flex flex-col gap-2">
          <p class="text-base text-[#595959] md:text-xl">
            {{ t("global.request_for_collection_of_these_bins") }}
          </p>
          <template
            v-for="(container, index) in lastAddedService?.collectedContainers?.collection"
            :key="index"
          >
            <RecollectionCardConfirmation :service="lastAddedService" :index="index" />
          </template>
        </div>
        <button
          class="ml-auto mt-4 w-full rounded-lg text-xl text-[#595959] md:w-1/6 btn-secondary"
          @click="editService(lastAddedService.id)"
        >
          {{ $t("global.edit_these_requests") }}
        </button>
      </div>
    </div>
  </transition>
  <transition
    enter-active-class="transition ease-out duration-300"
    enter-from-class="transform translate-y-full opacity-0"
    enter-to-class="transform translate-y-0 opacity-100"
    leave-active-class="transition ease-in duration-200"
    leave-from-class="transform translate-y-0 opacity-100"
    leave-to-class="transform translate-y-full opacity-0"
  >
    <div v-if="showConfirmationEditing && !showConfirmationCreation">
      <div class="fixed inset-0 z-5 bg-black opacity-50" />
      <div
        class="absolute fixed bottom-0 right-0.5 z-10 w-full rounded-2xl rounded-bl-none rounded-br-none bg-white p-5"
      >
        <div class="mb-4 flex items-center justify-between p-4">
          <div class="flex items-center gap-2">
            <CheckCircle :size="24" color="#15C28E" />
            <p class="text-xl text-[#595959] font-semibold">
              {{ t("global.your_changes_have_been_successfully_saved") }}
            </p>
          </div>
          <X :size="18" @click="closeEditConfirmation" />
        </div>
      </div>
    </div>
  </transition>
</template>

<route lang="yaml">
meta:
  layout: home
  requiresAuth: true
</route>
