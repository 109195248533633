import type { Unsubscribe } from 'firebase/auth'
import { fb_auth } from '~/services/firebase'
import type { UserModule } from '~/types'

let unsubscribe: Unsubscribe | null = null

export const install: UserModule = ({ isClient, router }) => {
  if (isClient) {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const query = Object.fromEntries(urlSearchParams.entries())

    router.beforeEach((to, from, next) => {
      // Clean up previous listener if it exists
      if (unsubscribe) {
        unsubscribe()
        unsubscribe = null
      }

      if (to.matched.some(record => record.meta.requiresAuth)) {
        let authCheckComplete = false

        unsubscribe = fb_auth.onAuthStateChanged((user) => {
          if (!authCheckComplete) {
            authCheckComplete = true
            if (user) {
              next()
            }
            else {
              next({
                path: '/auth/login',
                query,
              })
            }
          }
        })

        // Set a timeout to ensure navigation occurs even if Firebase is slow
        setTimeout(() => {
          if (!authCheckComplete) {
            authCheckComplete = true
            next({
              path: '/auth/login',
              query,
            })
          }
        }, 5000) // 5 second timeout
      }
      else {
        next()
      }
    })
  }
}
