import { gql } from '@apollo/client/core'

export const PRICE_LIST_QUERY_LIST = gql`
  query PriceList {
    prices_list {
      end_date
      id
      rm_id
      price
      start_date
      status
      type
      unit
      wp_id
      wr_id
      vat
      producer {
        client_type
        id
        name
      }
      raw_material {
        name
        id
        is_sold
        client_id
      }
    }
  }
`

export const PRICE_LIST_QUERY_LIST_BY_ID = gql`
  query PriceListById($id: Int!) {
    prices_list_by_pk(id: $id) {
      end_date
      id
      rm_id
      price
      start_date
      status
      type
      unit
      wp_id
      wr_id
      vat
    }
  }
`

export const PRICE_LIST_QUERY_INSERT = gql`
  mutation InsertPriceList($input: prices_list_insert_input!) {
    insert_prices_list_one(object: $input) {
      end_date
      id
      rm_id
      price
      start_date
      status
      type
      unit
      wp_id
      wr_id
      vat
      producer {
        client_type
        id
        name
      }
      raw_material {
        name
        id
        client_id
      }
    }
  }
`

export const PRICE_LIST_QUERY_UPDATE = gql`
  mutation UpdatePriceList($id: Int!, $input: prices_list_set_input!) {
    update_prices_list(where: { id: { _eq: $id } }, _set: $input) {
      affected_rows
      returning {
        end_date
        id
        rm_id
        price
        start_date
        status
        type
        unit
        wp_id
        wr_id
        vat
      }
    }
  }
`

export const PRICE_LIST_QUERY_UPDATE_BY_PK = gql`
  mutation UpdatePriceList($id: Int!, $input: prices_list_set_input!) {
    update_prices_list_by_pk(pk_columns: { id: $id }, _set: $input) {
      end_date
      id
      rm_id
      price
      start_date
      status
      type
      unit
      wp_id
      wr_id
      vat
      producer {
        client_type
        id
        name
      }
      raw_material {
        name
        id
        client_id
      }
    }
  }
`

export const PRICE_LIST_QUERY_DELETE = gql`
  mutation DeletePriceList($id: Int!) {
    delete_prices_list_by_pk(id: $id) {
      id
    }
  }
`
