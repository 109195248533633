<script setup lang="ts">
import {
  CheckSquare,
  Square,
  TagIcon,
  XIcon,
} from 'lucide-vue-next'
import { CollectStatus } from '~/types/graphql-backend-types/gql-types'

const props = defineProps({
  state: {
    type: String as PropType<CollectStatus>,
    required: true,
  },
  id: {
    type: String || Number,
    required: true,
  },
})

const emit = defineEmits<{
  'update:status': [value: CollectStatus]
}>()

const open = defineModel<boolean>()

const { getCurrentUser } = useUsersStore()
const { addToast } = useToast()
const { t } = useI18n()
const { getServiceById, updateService } = useCollectsStore()

const status = ref<CollectStatus>() as Ref<CollectStatus>
const isLoggedInTD = ref(false)

async function updateStatus() {
  const serviceLicencePlate = getServiceById(props.id)?.licensePlate
  if (isLoggedInTD.value) {
    if (status.value === CollectStatus.Finished && serviceLicencePlate === null) {
      addToast(t('global.licence_plate_is_required_to_finish_the_service'), { type: 'error' })
      return
    }
  }
  const update = {
    id: props.id,
    status: status.value,
  }
  await updateService(update)
  emit('update:status', status.value)
  open.value = false
}

async function isUserLoggedInTD() {
  const client = await getCurrentUser()
  if (client.isConnectedToTrackdechets) {
    isLoggedInTD.value = true
  }
  else {
    isLoggedInTD.value = false
  }
}

const statusColor = [
  { tag: '!bg-[#E6F7FF]', label: '!text-[#096DD9]' },
  { tag: '!bg-[#F2F2FF]', label: '!text-[#3A1EE3]' },
  { tag: '!bg-[#FAF5FF]', label: '!text-[#7E22CE]' },
  { tag: '!bg-[#FFF7ED]', label: '!text-[#C2410C]' },
  { tag: '!bg-[#FEF2F2]', label: '!text-[#B91C1C]' },
  { tag: '!bg-[#ECFDF6]', label: '!text-[#067659]' },
  { tag: '!bg-[#F9FAFB]', label: '!text-[#64748B]' },
]

const stateOptions = [
  { value: CollectStatus.RequestSent, comment: t('global.awaiting_recycler_validation') },
  { value: CollectStatus.Confirmed, comment: t('global.upcoming service') },
  { value: CollectStatus.InTreatment, comment: t('global.service_received') },
  { value: CollectStatus.AdminInProgress, comment: t('global.documentation_being_finalized') },
  { value: CollectStatus.HasIncident, comment: t('global.problems_with_the_service') },
  { value: CollectStatus.Finished, comment: t('global.service_validated_and_closed') },
  { value: CollectStatus.Cancelled, comment: t('global.service_cancelled_and_not_completed') },
]

function closeModal() {
  status.value = props.state
  open.value = false
}
watch(
  () => props.state,
  (newValue) => {
    status.value = newValue as CollectStatus
  },
)

onMounted(() => {
  isUserLoggedInTD()
})
</script>

<template>
  <WModal v-model="open">
    <template #title>
      <div class="flex flex-col gap-2 text-primary md:gap-4">
        <div class="flex flex-col gap-2">
          <div class="flex justify-between">
            <div class="flex items-center gap-2">
              <h2 class="text-xl font-semibold">
                {{ $t("global.status") }}
              </h2>
            </div>
            <button class="border border-transparent rounded p-1 transition">
              <XIcon :size="18" color="#71717A" class="cursor-pointer" @click="closeModal" />
            </button>
          </div>
        </div>
      </div>
    </template>
    <template #default>
      <div v-for="(s, index) in stateOptions" :key="index" class="flex flex-col gap-2">
        <div :class="s.value === status ? 'bg-[#F5F5F5]' : ''" class="w-full flex items-center justify-between gap-10 rounded">
          <button
            class="w-full flex items-center justify-between gap-10 rounded p-2"
            @click="status = s.value"
          >
            <div class="flex items-center justify-start">
              <TagIcon :size="18" :color="s.value === status ? '#15C28E' : ''" />
            </div>
            <div class="col-start-2 col-end-5 flex place-items-start items-center justify-start gap-2">
              <Tag
                class="whitespace-nowrap"
                :tag="t(`collect.status_${s.value}`)"
                :tag-classes="statusColor[index]"
              />
              <p class="whitespace-nowrap text-sm text-[#202531]">
                {{ s.comment }}
              </p>
            </div>
            <div class="w-full flex items-center justify-end">
              <CheckSquare v-if="s.value === status" :size="18" color="#15C28E" />
              <Square v-else :size="18" />
            </div>
          </button>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="w-full flex place-items-center items-center justify-end gap-2">
        <button class="btn-secondary" @click="closeModal">
          {{ t("global.close") }}
        </button>
        <button class="btn-primary" @click="updateStatus">
          {{ t("global.validate") }}
        </button>
      </div>
    </template>
  </WModal>
</template>
