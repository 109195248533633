<script lang="ts" setup>
import _ from 'lodash'
import {
  ChevronDown,
  ChevronsUpDown,
  ChevronUp,
  MoveUpRight,
  UploadIcon,
} from 'lucide-vue-next'
import { unparse } from 'papaparse'

interface TableColumn {
  key: string
  label: string
  searchable?: boolean
  sortType?: 'string' | 'number' | 'date' | 'boolean'
}

interface TableSort {
  key: string
  order: null | 'asc' | 'desc'
}

interface TableClasses {
  row?: string
  cell?: string
}

/**
 * @modelValue : string[]
 * (ids of the selected elements)
 *
 * @modelKey : string
 * (This key is used to identify the objects in the rows array.
 *  The related values must be STRINGS.
 *  It is used to select the objects in the table)
 *
 * @columns : TableColumn[]
 * (define the columns parameters :
 *   key => key of the objects in rows that will display
 *   label => the label that will display in the thead
 *   searchable => optional, if true the col will be both sortable and searchable through the OSearchBar
 *   sortType => optional, if defined : searchable must be true. It indicates to the sorter which type of data he is processing
 * )
 *
 * @rows : Object[]
 * (Any objects. As soon as a key is matching the :columns prop, it wil render to the user)
 *
 * @exportButton : Boolean
 * (display a button to export the table data in a csv file)
 *
 * @hasSearchBar : Boolean
 * (display a search bar to filter the table data)
 *
 * @elementsPerPage : Number
 * (default amount of items displayed in a single pagination)
 *
 * @totalElements : Number
 * (total amount of items in the table, used for async paginations)
 *
 * @defaultPagination : Number
 * (default pagination displayed by the OTable)
 *
 * @defaultSort : TableSort
 * (default sorting of the table, mainly used to remember the user config in localStorage)
 *
 * @classes : TableClasses
 * (add style exceptions to various table elements)
 *
 * @loading : Boolean
 * (display a loader in the table)
 *
 * @debounceValue : Number
 * (Adds a timer after each data realted actions from the user, used to avoid calculus or database overload)
 */

const props = defineProps({
  modelValue: {
    type: Array<string>,
    required: false,
    default: null,
  },
  modelKey: {
    type: String,
    required: false,
    default: 'id',
  },
  columns: {
    type: Array as PropType<TableColumn[]>,
    required: true,
    default: [] as any,
  },
  rows: {
    type: Array as PropType<any[]>,
    required: true,
    default: [] as any,
  },
  exportButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  hasSearchBar: {
    type: Boolean,
    required: false,
    default: true,
  },
  elementsPerPage: {
    type: Number,
    required: false,
    default: 10,
  },
  totalElements: {
    type: Number,
    required: false,
    default: 0,
  },
  defaultPagination: {
    type: Number,
    required: false,
    default: 1,
  },
  hideRowsSelection: {
    type: Boolean,
    required: false,
    default: false,
  },
  defaultSort: {
    type: Object as PropType<TableSort>,
    required: false,
    default() {
      return { key: '', order: '' }
    },
  },

  classes: {
    type: Object as PropType<TableClasses>,
    required: false,
    default() {
      return {
        row: '',
        cell: '',
      }
    },
  },

  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
  debounceValue: {
    type: Number,
    required: false,
    default: 0,
  },
})

const emit = defineEmits<{
  (e: 'update:modelValue', val: object): void
  (e: 'on-sort-change', val: TableSort): void
  (e: 'on-pagination-change', val: number, elementsPerPage: number): void
  (e: 'on-cell-click', val: object): void
  (e: 'on-row-click', val: object): void
}>()

const { t } = useI18n()

const { rows } = toRefs(props)

const state = reactive({
  columns: props.columns as TableColumn[],
  rows: props.rows as object[],

  pagination: props.defaultPagination as number,
  sort: props.defaultSort as TableSort,
  search: '',
  elementsPerPage: props.elementsPerPage as number,
})
const data = reactive({
  selectedRows: [] as object[],
  displayedRows: [] as object[],
})

const elementsPerPageOptions = computed((): any => {
  const values = [5, 10, 20, 30, 50]
  if (!values.find(value => value === props.elementsPerPage)) {
    values.push(props.elementsPerPage)
    values.sort((a, b) => a - b)
  }
  return values.map((value) => {
    return { label: `${value} ${t('global.rows')}`, value }
  })
})

const filteredRows = computed(() => {
  const search = state.search.toLowerCase()
  if (search === '')
    return state.rows

  return state.rows.filter((row: any) => {
    return state.columns.find((col: TableColumn) => {
      if (!col.searchable || !row[col.key])
        return false

      const val = row[col.key]
      if (col.sortType === 'string')
        return val?.toLowerCase()?.includes(search) || false
      else if (typeof val === 'number')
        return val.toString() === search
      else if (typeof val === 'boolean')
        return val.toString() === search
      else if (col.sortType === 'date')
        return JSON.stringify(val).toLowerCase().includes(search)
      else
        return false
    })
  })
})

const pageAmount = computed((): number => {
  if (props.totalElements > 0)
    return Math.ceil(props.totalElements / state.elementsPerPage)

  return Math.ceil(filteredRows.value.length / state.elementsPerPage) || 1
})

const areAllRowsSelected = computed((): boolean => {
  return data.selectedRows.length === state.rows.length && state.rows.length > 0
})

function columnCanBeSorted(column: TableColumn) {
  return column.searchable
}

function orderBy(col: TableColumn) {
  if (!columnCanBeSorted(col)) {
    state.sort.key = ''
    state.sort.order = null
    return
  }

  if (state.sort.key === col.key) {
    switch (state.sort.order) {
      case 'asc':
        state.sort.order = 'desc'
        break
      case 'desc':
        state.sort.order = null
        state.sort.key = ''
        break
      default:
        state.sort.order = 'asc'
        break
    }
  }
  else {
    state.sort.key = col.key
    state.sort.order = 'asc'
  }

  sortRows()
}

function onSearch() {
  _.debounce(() => {
    if (state.search === '') {
      loadPaginatedRows()
      return
    }

    const start = (state.pagination - 1) * state.elementsPerPage
    const end = start + state.elementsPerPage
    data.displayedRows = filteredRows.value.slice(start, end)

    if (state.pagination > pageAmount.value || state.pagination === 0)
      state.pagination = pageAmount.value || 1
  }, props.debounceValue)()
}

function sortRows() {
  if (state.sort.key === '')
    return

  const col: TableColumn | undefined = state.columns.find((c: TableColumn) => c.key === state.sort.key)
  if (!col)
    return

  const order = state.sort.order === 'asc' ? 1 : -1
  const rows = state.rows.sort((a, b) => {
    const aVal: any = a[col.key]
    const bVal: any = b[col.key]

    if (aVal < bVal)
      return -1 * order
    if (aVal > bVal)
      return 1 * order
    return 0
  })

  const start = (state.pagination - 1) * state.elementsPerPage
  const end = start + state.elementsPerPage
  data.displayedRows = rows.slice(start, end)

  emit('on-sort-change', state.sort)
}

function toggleEveryRowSelected() {
  if (data.selectedRows.length === state.rows.length)
    data.selectedRows = []
  else
    data.selectedRows = state.rows.map((row: any) => row[props.modelKey])

  emit('update:modelValue', data.selectedRows)
}

function toggleRowSelected(rowValue: string) {
  if (data.selectedRows.includes(rowValue))
    data.selectedRows = data.selectedRows.filter((row: any) => row !== rowValue)
  else
    data.selectedRows.push(rowValue)

  emit('update:modelValue', data.selectedRows)
}

function onCellClick(row: object, col: TableColumn, rowIndex: number, colIndex: number) {
  const cell: any = row[col.key]
  emit('on-cell-click', { cell, col, row, colIndex, rowIndex })
}

function onRowClick(row: object, rowIndex: number) {
  emit('on-row-click', { row, rowIndex })
}

function onPaginationChange(e: number) {
  state.pagination = e ?? 1
  onSearch()
  emit('on-pagination-change', state.pagination, state.elementsPerPage)
}

function loadPaginatedRows() {
  data.displayedRows = []

  const start = (state.pagination - 1) * state.elementsPerPage
  const end = start + state.elementsPerPage
  data.displayedRows = state.rows.slice(start, end)
}

function exporCSV() {
  const csv = unparse(props.rows, {
    delimiter: ';',
    header: true,
  })

  // download csv to local computer
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)
  link.setAttribute('href', url)
  link.setAttribute('download', `data_${new Date().toISOString()}.csv`)
  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

function loadTable() {
  loadPaginatedRows()
}

watch(
  () => rows,
  () => {
    props.rows = props.rows as object[]
    loadTable()
  },
  { immediate: true, deep: true },
)

watch(
  () => props.columns,
  () => {
    state.columns = props.columns as TableColumn[]
    loadTable()
  },
  { immediate: true },
)

watch(
  () => state.elementsPerPage,
  () => loadPaginatedRows(),
)

watch(
  () => props.defaultPagination,
  () => {
    state.pagination = props.defaultPagination
    loadPaginatedRows()
  },
)
</script>

<template>
  <div class="w-full flex flex-1 flex-col gap-2 md:gap-4 sm:gap-3">
    <div
      v-if="props.hasSearchBar || props.exportButton || $slots['table-actions']"
      class="flex flex-col gap-2 lg:flex-row md:gap-4 sm:gap-3"
    >
      <div
        v-if="props.hasSearchBar"
        class="h-full w-full flex"
        :class="$slots['table-actions'] ? 'lg:w-1/4 lg:min-w-80' : ''"
      >
        <OSearchBar
          v-model="state.search"
          @update:model-value="onSearch"
        />
      </div>
      <div
        v-else
        class="h-full w-full flex"
      />
      <button
        v-if="props.exportButton"
        class="btn-secondary"
        :title="$t('global.export')"
        @click="exporCSV()"
      >
        <UploadIcon :size="18" />
      </button>
      <div
        v-if="$slots['table-actions']"
        class="w-full flex lg:justify-end"
        :class="props.hasSearchBar ? 'lg:w-3/4' : ''"
      >
        <slot name="table-actions" />
      </div>
    </div>
    <div class="inline-block w-full flex flex-col overflow-x-auto rounded bg-white align-middle">
      <table class="w-full">
        <thead class="h-10 bg-[#FAFAFA]">
          <th
            v-if="props.modelValue"
            class="sticky z-5 bg-[#FCFCFD] -left-3"
          >
            <FormKit
              :key="data.selectedRows.length"
              type="checkbox"
              name="allcols"
              :value="areAllRowsSelected"
              decorator-icon="ph-check-bold"
              outer-class="$reset w-fit ml-4 mr-2"
              @click.stop.prevent="toggleEveryRowSelected"
            />
          </th>
          <th
            v-for="(column, colIndex) in state.columns"
            :key="column.key"
            scope="col"
            class="group bg-[#FCFCFD] text-sm text-[#667085]"
            @click="orderBy(column)"
          >
            <div
              class="flex items-center gap-1 truncate p-2 py-3 font-normal"
              :class="[
                column.searchable ? 'cursor-pointer' : '',
                state.sort.key === column.key && column.searchable ? 'underline' : '',
              ]"
            >
              <span>{{ column.label }}</span>
              <template v-if="column.searchable">
                <div class="scale-80">
                  <div
                    v-if="state.sort.order !== null && state.sort.key === column.key"
                  >
                    <ChevronUp v-if="state.sort.order === 'asc'" :size="14" />
                    <ChevronDown v-else :size="14" />
                  </div>
                  <template v-else>
                    <ChevronsUpDown :size="14" />
                  </template>
                </div>
              </template>
            </div>
          </th>
          <th
            v-if="$slots['row-actions']"
            class="sticky bg-[#FCFCFD] -right-0.5"
          />
        </thead>
        <tbody v-if="props.loading" class="border border-[#EAECF0] rounded">
          <tr>
            <td
              :colspan="props.columns.length + 1"
              class="py-2"
            >
              <Loader />
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr
            v-for="(row, rowIndex) in rows"
            :key="rowIndex"
            class="mt-10 text-[#1D2939]"
            :class="props.classes.row"
            @click="onRowClick(row, rowIndex)"
          >
            <td
              v-if="props.modelValue"
              class="sticky border border-[#EAECF0] bg-white -left-3"
              :class="props.classes.cell"
            >
              <FormKit
                :key="data.selectedRows.length"
                type="checkbox"
                :name="row[props.modelKey]"
                :value="data.selectedRows.includes(row[props.modelKey])"
                decorator-icon="ph-check-bold"
                outer-class="ml-4 mr-2"
                @click.stop.prevent="toggleRowSelected(row[props.modelKey])"
              />
            </td>

            <td
              v-for="(col, colIndex) in state.columns"
              :key="col.key"
              class="border border-[#EAECF0] bg-white"
              @click="onCellClick(row, col, rowIndex, colIndex)"
            >
              <template v-if="col.key === 'cognyx_sidebar_action'">
                <div class="h-full w-full flex items-center justify-center">
                  <button
                    class="mx-auto border border-transparent p-1 text-sm text-[#475467] transition hover:border-[#E6E6E5]"
                    @click.stop.prevent="onCellClick(row, col, rowIndex, colIndex)"
                  >
                    <MoveUpRight :size="18" />
                  </button>
                </div>
              </template>
              <div
                v-else
                class="w-full flex whitespace-nowrap p-2"
                :class="props.classes.cell"
              >
                <slot
                  name="cells"
                  :col="col"
                  :col-index="colIndex"
                  :row="row"
                  :row-index="rowIndex"
                >
                  {{ row[col.key] }}
                </slot>
              </div>
            </td>

            <td
              v-if="$slots['row-actions']"
              class="sticky border border-[#EAECF0] bg-white -right-0.5"
            >
              <slot
                name="row-actions"
                :row="row"
                :row-index="rowIndex"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!hideRowsSelection" class="flex flex-row justify-between">
      <slot name="footer">
        <o-pagination
          v-model="state.pagination"
          :number-page="pageAmount"
          @update:model-value="onPaginationChange"
        />
      </slot>
      <div
        v-if="state.columns.length > 0"
        class="right-0 flex flex-shrink-0 items-center gap-2 whitespace-nowrap md:gap-4 sm:gap-3"
      >
        <span class="text-[#676765]">{{ `${$t("global.show")} :` }}</span>
        <FormKit
          v-model="state.elementsPerPage"
          type="select"
          :selected="state.elementsPerPage"
          :options="elementsPerPageOptions"
          inner-class="important:mb-0"
          input-class="$reset py-0.5 pl-3 pr-8 text-base text-[#404040] rounded appearance-none bg-transparent placeholder:text-[#C0C0BF] focus:outline-none focus:ring-0 focus:shadow-none data-[placeholder=&quot;true&quot;]:text-[#C0C0BF]"
          suffix-icon="ph-caret-up-down-bold"
        />
      </div>
    </div>
  </div>
</template>
