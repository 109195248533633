import { i18n } from '~/modules/i18n'

let uid = ''
const t = i18n.global.t as any

export async function loadStores(userId: string) {
  const { loadUsers } = useUsersStore()
  userStore().setStoresLoadingState(false)
  uid = userId
  await loadUsers()
  userStore().setStoresLoadingMsg(t('stores.users_loading'))
  userStore().loadUser(uid)
  userStore().setStoresLoadingMsg(t('stores.data_loading'))
  await loadAppStores()
}

async function loadAppStores() {
  const { loadServices } = useCollectsStore()
  const { loadTreatmentCodes, loadWasteCodes } = useCodeValidationStore()
  const storesPromises = []
  await orgStore().loadOrg()
  storesPromises.push(
    sitesStore().loadSites(),
    rawMaterialsContainersStore().loadRawMaterialsContainers(),
    loadServices(),
    priceListStore().loadPricesLists(),
    loadTreatmentCodes(),
    loadWasteCodes(),
    useClientsStore().fetchClients(),
  )
  await Promise.all(storesPromises)
  userStore().setStoresLoadingState(true)
}
