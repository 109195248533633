import { COLLECTS_QUERY_SINGLE, RATE_COLLECT_NPS } from '~/queries/collects'
import type { CollectGraphql, IncidentGraphql, RateCollectNpsInputGraphql } from '~/types/graphql-backend-types/gql-types'
import { getPricesWithoutVTA } from '~/utils/prices'

export const useCollectStore = defineStore('collect', () => {
  const collect = ref<CollectGraphql>({} as CollectGraphql)
  const collectLoading = ref<boolean>(false)

  const incidents = computed(() => collect.value?.incidents?.collection) as ComputedRef<IncidentGraphql[]>

  const incidentsPenalty = computed(() => {
    let total = 0
    incidents.value.forEach((incident: IncidentGraphql) => {
      total += incident.penaltyAmount ? incident.penaltyAmount : 0
    })
    return total
  })

  const VAT = computed(() => {
    if (!collect.value)
      return 0
    return (
      (collect.value.collectedContainers.collection
        .map((item, index: number) => {
          return getPricesWithoutVTA(
            item?.material?.activePricing?.price as number,
            collect.value.collectedContainers.collection[index]?.quantity,
          )
        })
        .reduce((a: number, b: number) => a + b, 0)
        * (collect.value.collectedContainers.collection[0]!.material?.activePricing?.vat ?? 0))
        / 100
        + (collect.value.transportPrice! * 20) / 100
        + (incidentsPenalty.value * 20) / 100
    )
  })

  const totalPriceMaterialsWithoutVAT = computed(() => {
    if (!collect.value)
      return 0
    let total = 0
    collect.value.collectedContainers.collection.forEach((container) => {
      if (container) {
        total += (container?.material?.activePricing?.price ?? 0) * (container?.quantity ?? 0)
      }
    })
    return total
  })

  const totalPriceMaterialsWithVAT = computed(() => {
    if (!collect.value)
      return 0
    return (
      totalPriceMaterialsWithoutVAT.value
      + (totalPriceMaterialsWithoutVAT.value
        * collect.value.collectedContainers.collection[0]?.material?.activePricing?.vat
        ? collect.value.collectedContainers.collection[0]?.material?.activePricing?.vat
        : 0)
        / 100
    )
  })

  const pricingOfMaterials = computed(() => {
    if (!collect.value)
      return 0
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(
      collect.value.collectedContainers.collection[0]?.material?.activePricing?.price as number,
    )
  })

  const transportPrice = computed(() => {
    return collect.value.transportPrice!
  })

  const totalPriceIsSold = computed(() => {
    if (!collect.value)
      return 0
    return totalPriceMaterialsWithoutVAT.value + transportPrice.value + incidentsPenalty.value + VAT.value
  })

  const totalPriceNotSold = computed(() => {
    if (!collect.value)
      return 0
    return totalPriceMaterialsWithVAT.value - ((transportPrice.value * 20) / 100 + (incidentsPenalty.value * 20) / 100)
  })

  async function loadSingleCollect(id: string) {
    if (!id)
      return

    const { query } = useGqlMikro()

    collectLoading.value = true
    const { data } = await query({ query: COLLECTS_QUERY_SINGLE, variables: { pagination: { }, filters: { ids: [id] } } })
    collectLoading.value = false
    collect.value = JSON.parse(JSON.stringify(data.collects.collection[0]))
    collect.value.startsAt = new Date(collect.value.startsAt).toISOString()
    return collect.value
  }

  function addDocumentToCollect(document: any) {
    collect?.value.documents.collection.push(document)
  }

  function removeDocumentFromCollect(documentId: string) {
    const documentIndex = collect?.value.documents.collection.findIndex(item => item?.id === documentId)
    collect?.value.documents.collection.splice(documentIndex, 1)
  }

  async function rateCollectNps(input: RateCollectNpsInputGraphql) {
    const { mutate } = useGqlMikro()
    const { collects } = storeToRefs(useCollectsStore())

    const { data, errors } = await mutate({
      mutation: RATE_COLLECT_NPS,
      variables: { input },
    })

    if (errors) {
      throw new Error(errors[0].message)
    }

    const cs = collects.value.collection.find(item => item?.id === input.collectId)
    if (cs) {
      cs.npsRating = data!.rateCollectNPS.npsRating
    }
    collect.value!.npsRating = data!.rateCollectNPS.npsRating

    return { data }
  }

  return {
    collect,
    collectLoading,
    VAT,
    totalPriceMaterialsWithoutVAT,
    totalPriceMaterialsWithVAT,
    pricingOfMaterials,
    incidents,
    incidentsPenalty,
    transportPrice,
    totalPriceIsSold,
    totalPriceNotSold,
    rateCollectNps,
    loadSingleCollect,
    addDocumentToCollect,
    removeDocumentFromCollect,
  }
})
