import { provideApolloClient } from '@vue/apollo-composable'
import _, { has } from 'lodash'
import { defineStore } from 'pinia'
import {
  PRICE_LIST_QUERY_DELETE,
  PRICE_LIST_QUERY_INSERT,
  PRICE_LIST_QUERY_LIST,
  PRICE_LIST_QUERY_LIST_BY_ID,
  PRICE_LIST_QUERY_UPDATE_BY_PK,
} from '~/queries/price-list'
import apolloClient from '../composables/graphql'

provideApolloClient(apolloClient)

export const priceListStore = defineStore({
  id: 'priceList',
  state: () => ({
    priceList: {
      list: [] as Array<any>,
    },
  }),
  getters: {
    getPricesList(): any[] {
      return this.priceList.list
    },
    getPricesListAsOptions(): any[] {
      const options = this.priceList.list.map((item) => {
        return {
          label: item.name,
          value: item.id,
        }
      })
      return options
    },
  },
  actions: {
    async loadPricesLists(): Promise<any> {
      const user: any = userStore().user
      if (!user)
        return

      const priceList = await apolloClient.query({ query: PRICE_LIST_QUERY_LIST })
      this.priceList.list = priceList.data?.prices_list

      return priceList
    },
    getPricesLists(): any {
      return this.priceList.list
    },
    getPricesListById(priceListId: string | number): any {
      return this.priceList.list.find(item => item.id == priceListId)
    },
    async loadPricesListById(priceListId: string | number): Promise<any> {
      const pricesList = await apolloClient.query({
        query: PRICE_LIST_QUERY_LIST_BY_ID,
        variables: {
          id: priceListId,
        },
      })
      return pricesList.data.prices_list_by_pk
    },
    getPricesListByClientId(clientId: string): any[] {
      return this.priceList.list.filter(item => item.wp_site.client_id === clientId)
    },
    async updatePricesList(priceList: any): Promise<void> {
      const user: any = userStore().user
      if (!user)
        return

      if (has(priceList, '__typename')) {
        delete priceList.__typename
      }
      const updatedPriceList = await apolloClient.mutate({
        mutation: PRICE_LIST_QUERY_UPDATE_BY_PK,
        variables: {
          id: priceList.id,
          input: priceList,
        },
      })

      const index = _.findIndex(this.priceList.list, { id: priceList.id })
      if (index !== -1) {
        this.priceList.list = [
          ...this.priceList.list.slice(0, index),
          updatedPriceList.data.update_prices_list_by_pk,
          ...this.priceList.list.slice(index + 1),
        ]
      }
    },
    async insertPricesList(priceList: any): Promise<void | Error> {
      const user: any = userStore().user
      if (!user)
        return

      this.priceList.list.forEach((item) => {
        if (item.rm_id === priceList.rm_id) {
          throw new Error('price.rm__id_already_exists')
        }
      })
      const insertedPriceList = await apolloClient.mutate({
        mutation: PRICE_LIST_QUERY_INSERT,
        variables: {
          input: priceList,
        },
      })

      this.priceList.list = [...this.priceList.list, insertedPriceList.data.insert_prices_list_one]
    },
    async deletePricesList(priceListId: number): Promise<void> {
      const user: any = userStore().user
      if (!user)
        return

      await apolloClient.mutate({
        mutation: PRICE_LIST_QUERY_DELETE,
        variables: {
          id: priceListId,
        },
      })
      this.priceList.list = this.priceList.list.filter(price => priceListId !== price.id)
    },
  },
})
